<template>
  <div class="left2">
    <CommonTitle title="故障统计"></CommonTitle>
    <div class="left2-chart">
      <CommonEchart :echart="echart" :option="lineOption"></CommonEchart>
    </div>
  </div>
</template>

<script>
import CommonTitle from "@c/commonTitle";
import CommonEchart from "@c/commonEchart";
import line from "../echarts/line";
export default {
  components: {
    CommonTitle,
    CommonEchart
  },
  data () {
    return {
      echart: {
        width: '100%',
        height: '100%'
      },
      data: null
    }
  },
  computed: {
    lineOption () {
      return line(this.data);
    }
  },
  created () {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await this.$http.getFaultStatisticsByDate({});
      if (res.success) {
        this.data = res.data;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.left2 {
  position: absolute;
  left: 30px;
  top: 340px;
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 286px;
  &-chart {
    width: 100%;
    flex: 1;
  }
}
</style>