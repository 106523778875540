<!--
 * @Description: 
 * @Author: FeiShu
 * @Date: 2024-09-04 18:10:22
 * @LastEditors: FeiShu
 * @LastEditTime: 2024-09-06 15:41:53
-->
<template>
  <div class="right1">
    <CommonTitle title="设备预警风险平均值"></CommonTitle>
    <div class="right1-chart">
      <CommonEchart :echart="echart" :option="radarOption"></CommonEchart>
    </div>
  </div>
</template>

<script>
import CommonTitle from "@c/commonTitle";
import CommonEchart from "@c/commonEchart";
import radar from "../echarts/radar";
export default {
  components: {
    CommonTitle,
    CommonEchart
  },
  data () {
    return {
      echart: {
        width: '100%',
        height: '100%'
      },
      data: [
        {
          name: '电气系统故障风险值',
          key: "electrical",
          value: 0
        },
        {
          name: '门系统故障风险值',
          key: "door",
          value: 0
        },
        {
          name: '导向系统故障风险值',
          key: "guide",
          value: 0
        },
        {
          name: '曳引系统故障风险值',
          key: "traction",
          value: 0
        },
      ]
    }
  },
  computed: {
    radarOption () {
      return radar(this.data);
    }
  },
  created () {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await this.$http.getAverageEquipmentWarningRisk({});
      if (res.success) {
        const { data } = res;
        this.data.forEach(v => {
          v.value = data[v.key];
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.right1 {
  position: absolute;
  top: 0;
  right: 30px;
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 360px;
  &-chart {
    width: 100%;
    flex: 1;
  }
}
</style>