<template>
  <div class="left3">
    <CommonTitle title="设备报警次数"></CommonTitle>
    <div class="left3-chart">
      <CommonEchart :echart="echart" :option="barOption"></CommonEchart>
    </div>
  </div>
</template>

<script>
import CommonTitle from "@c/commonTitle";
import CommonEchart from "@c/commonEchart";
import bar from "../echarts/bar";
export default {
  components: {
    CommonTitle,
    CommonEchart
  },
  data () {
    return {
      echart: {
        width: '100%',
        height: '100%'
      },
      data: []
    }
  },
  computed: {
    barOption () {
      return bar(this.data);
    }
  },
  created () {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await this.$http.getEquipmentAlarmCount({});
      if (res.success) {
        this.data = res.data;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.left3 {
  position: absolute;
  left: 30px;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 320px;
  &-chart {
    width: 100%;
    flex: 1;
  }
}
</style>