<!--
 * @Description: 
 * @Author: FeiShu
 * @Date: 2024-09-06 14:30:14
 * @LastEditors: FeiShu
 * @LastEditTime: 2024-09-06 14:35:56
-->
<template>
  <div class="center-bg" @click="$router.push('/detail')"></div>
</template>

<script>
export default {
  data () {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.center-bg {
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  width: 650px;
  height: 646px;
  background: url('~@a/images/dianti.webp') no-repeat;
  background-size: 100% 100%;
  background-position: 0 0;
  cursor: pointer;
}
</style>