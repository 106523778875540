/*
 * @Description: 
 * @Author: FeiShu
 * @Date: 2024-09-04 15:51:10
 * @LastEditors: FeiShu
 * @LastEditTime: 2024-09-10 13:49:14
 */
import { pxToRem } from "@/utils"
const lineOption = (data) => {
  const obj = data || {};
  const xData = Object.keys(obj);
  const values = Object.values(obj);
  const option = {
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      show: false
    },
    grid: {
      top: "5%",
      left: '3%',
      right: '6%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      // data: ['01','02','03','04','05','06'],
      data: xData,
      axisLabel: {
        interval: 0, // 强制展示
        textStyle: {
            fontSize: 14,
            color: 'rgba(255,255,255,0.8)'
        },
      },
      axisLine: {
        lineStyle: {
            color: '#4A90E2'
        }
      },
      axisTick: {
        show: false
      },
    },
    yAxis: {
      name: '',
      type: 'value',
      axisLabel: {
          formatter: '{value}',
          textStyle: {
              color: 'rgba(255,255,255,0.8)'
          }
      },
      splitLine: {
          lineStyle: {
              color: '#435A7F'
          }
      },
      axisLine: {
        show: false
      }
    },
    series: [
      {
        name: "故障统计",
        type: "line",
        showSymbol: false,
        color: "#5FE3FF",
        areaStyle: {
          opacity: 0.8,
          color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                  {
                      offset: 0,
                      color: "#286994",
                  },
                  {
                      offset: 1,
                      color: "RGBA(0, 222, 242, 0)",
                  },
              ],
              global: false, // 缺省为 false
          },
        },
        // data: [88,100,92,40,90,30]
        data: values
      }
    ]
  }

  return option;
}

export default lineOption;