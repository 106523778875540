/*
 * @Description: 
 * @Author: FeiShu
 * @Date: 2024-09-04 16:41:57
 * @LastEditors: FeiShu
 * @LastEditTime: 2024-09-11 11:16:07
 */
import { pxToRem } from "@/utils"
const barOption = (data) => {
  const list = data || [];
  const xData = data.map(v => v.week);
  const values1 = data.map(v => v.lastWeek); // 上周
  const values2 = data.map(v => v.thisWeek); // 本周
  const option = {
    legend: {
      data: ['上周','本周'],
      right: "3%",
      top: "4%",
      textStyle: {
        color: "rgba(255,255,255,0.8)",
        fontSize: 14
      }, 
      itemWidth: 12,  // 设置宽度
      itemHeight:12, // 设置高度
      itemGap: 12 // 设置间距
    },
    grid: {
      top: "20%",
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      // data: ["周一","周二","周三","周四","周五","周六","周日"],
      data: xData,
      axisLabel: {
        textStyle: {
            color: 'rgba(255,255,255,0.8)'
        },
      },
      axisLine: {
        lineStyle: {
            color: '#4A90E2'
        }
      },
      axisTick: {
        show: false
      },
    },
    yAxis: {
      name: '次数',
      nameTextStyle: {
        color: "rgba(255, 255, 255, 0.80)",
        fontSize: 14
      },
      type: 'value',
      axisLabel: {
          formatter: '{value}',
          textStyle: {
              color: 'rgba(255,255,255,0.8)'
          }
      },
      splitLine: {
          lineStyle: {
              color: '#435A7F'
          }
      },
      axisLine: {
        show: false
      }
    },
    series: [
      {
        name: "上周",
        type: "bar",
        barWidth: "14px",
        itemStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
                {
                    offset: 0,
                    color: "#94D7FB",
                },
                {
                    offset: 1,
                    color: "#5A98F8",
                },
            ],
            global: false, // 缺省为 false
          },
        },
        // data: [120,125,120,100,110,80,60]
        data: values1
      },
      {
        name: "本周",
        type: "bar",
        barWidth: "14px",
        itemStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
                {
                    offset: 0,
                    color: "#94FBEF",
                },
                {
                    offset: 1,
                    color: "#2EBC22",
                },
            ],
            global: false, // 缺省为 false
          },
        },
        // data: [90,110,90,80,85,80,40]
        data: values2
      },
    ]
  }

  return option;
}

export default barOption;