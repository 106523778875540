import { pxToRem } from "@/utils"
const radarOption = (data) => {
  const values = data.map(v => v.value);
  const option = {
    tooltip: {
      show: false // 弹层数据去掉
    },
    radar: {
      center: ['50%','50%'],
      radius: "50%",
      name: {
        textStyle: {
          color: 'rgba(255,255,255,0.8)',
          fontSize: 14,
          fontWeight: 400,
          fontFamily: 'PingFang SC, PingFang SC',
          fontStyle: 'normal',
          rich: {
            a: {
              fontSize: 14,
            },
            b: {
              fontSize: 18,
              fontFamily: 'PangMenZhengDao, PangMenZhengDao',
              color: 'rgba(133, 223, 255, 1)',
              fontWeight: 400,
            }
          }
        },
        formatter: function (name) {
          const obj = data.find(v => v.name == name);
          return name + '  ' + '{b|' + obj.value + '}'
        }
      },
      indicator: [
        {
          name: '电气系统故障风险值',
          max: 100
        },
        {
          name: '门系统故障风险值',
          max: 100
        },
        {
          name: '导向系统故障风险值',
          max: 100
        },
        {
          name: '曳引系统故障风险值',
          max: 100
        },
      ],
      splitArea: {
        // 坐标轴在 grid 区域中的分隔区域，默认不显示。
        show: true,
        areaStyle: {
          // 分隔区域的样式设置。
          color: ['#131A22'] // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
        }
      },
      axisLine: {
        // 指向外圈文本的分隔线样式
        lineStyle: {
          color: '#343B41'
        }
      },
      splitLine: {
        lineStyle: {
          type: 'solid',
          color:'#343B41', // 分隔线颜色
          width: 1 // 分隔线线宽
        }
      }
    },
    series: [
      {
        type: "radar",
        symbolSize: 5,
        data: [
          {
            value: values
          }
        ],
        areaStyle: {
          normal: {
            color: {
                type: 'radial',
                x: 0.5,
                y: 0.5,
                r: 0.5,
                colorStops: [{
                    offset: 0, color: 'rgba(58, 172, 255, 0.20)' // 0% 处的颜色
                }, 
                {
                    offset: 1, color: 'rgba(58, 172, 255, 1)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
            }
          }
        },
        itemStyle: { // 折线拐点标志的样式。
          normal: { // 普通状态时的样式
              lineStyle: {
                  width: 1
              },
              opacity: 0.3
          },
          emphasis: { // 高亮时的样式
              lineStyle: {
                  width: 5
              },
              opacity: 0
          }
        },
      }
    ]
  }

  return option;
}

export default radarOption;