<!--
 * @Description: 
 * @Author: FeiShu
 * @Date: 2024-01-10 15:41:20
 * @LastEditors: FeiShu
 * @LastEditTime: 2024-09-06 14:33:05
-->
<template>
  <div class="home">
    <Left1></Left1>
    <Left2></Left2>
    <Left3></Left3>
    <Center></Center>
    <Bottom></Bottom>
    <Top></Top>
    <Right1></Right1>
    <Right2></Right2>
    <Right3></Right3>
  </div>
</template>

<script>
import Left1 from "./modules/left1.vue";
import Left2 from "./modules/left2.vue";
import Left3 from "./modules/left3.vue";
import Bottom from "./modules/bottom.vue";
import Top from "./modules/top.vue";
import Right1 from "./modules/right1.vue";
import Right2 from "./modules/right2.vue";
import Right3 from "./modules/right3.vue";
import Center from "./modules/center.vue";
export default {
  components: {
    Left1,
    Left2,
    Left3,
    Bottom,
    Top,
    Right1,
    Right2,
    Right3,
    Center
  },
  data () {
    return {
      
    }
  },
  created () {
    
  }
}
</script>

<style lang="scss" scoped>
.home {
  position: relative;
  width: 100%;
  height: calc(100% - 80px);
}
</style>