<!--
 * @Description: 
 * @Author: FeiShu
 * @Date: 2024-09-04 14:33:22
 * @LastEditors: FeiShu
 * @LastEditTime: 2024-09-06 14:57:24
-->
<template>
  <div class="left1">
    <div class="left1-item" v-for="(item,index) in list" :key="index">
      <img :src="item.icon" alt="">
      <p>{{ item.label }}</p>
      <CountTo :startVal='0' :endVal="Number(item.value)" :duration='3000' separator=""></CountTo>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list: [
        {
          icon: require('@a/images/guzhang.png'),
          label: "今日累计事件/台",
          key: "cumulative_events",
          value: 0
        },
        {
          icon: require('@a/images/beikun.png'),
          label: "今日困人",
          key: "trapped_people",
          value: 20
        },
        {
          icon: require('@a/images/jiandu.png'),
          label: "今日检修/台",
          key: "maintenance",
          value: 50
        },
        {
          icon: require('@a/images/weibao.png'),
          label: "今日维保/台",
          key: "maintenance_service",
          value: 100
        },
      ]
    }
  },
  created () {
    this.getList();
  },
  methods: {
    async getList () {
      const res = await this.$http.todayCount({});
      if (res.success) {
        const { data } = res;
        this.list.forEach(v => {
          v.value = data[v.key];
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.left1 {
  position: absolute;
  left: 30px;
  top: 0;
  width: 500px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 242px;
    height: 152px;
    margin-bottom: 16px;
    background: url('~@a/images/left_bg.png') no-repeat;
    background-size: 100%;
    background-position: 0 0;
    >img {
      width: 32px;
      height: 32px;
    }
    p {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: rgba(255,255,255,0.8);
      line-height: 19px;
      margin: 8px 0;
    }
    span {
      font-family: PangMenZhengDao, PangMenZhengDao;
      font-weight: 400;
      font-size: 28px;
      color: #00FDE0;
      line-height: 33px;
      letter-spacing: 1px;
    }
  }
}
</style>