<!--
 * @Description: 
 * @Author: FeiShu
 * @Date: 2024-09-04 18:00:44
 * @LastEditors: FeiShu
 * @LastEditTime: 2024-09-06 15:06:40
-->
<template>
  <div class="top">
    <div class="top-l">
      <img :src="icon" alt="">
      <span>电梯量</span>
    </div>
    <div class="top-r">
      <CountTo :startVal='0' :endVal="Number(count)" :duration='3000' separator=""></CountTo>
      <em>台</em>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      icon: require('@a/images/dtl.png'),
      count: 0
    }
  },
  created () {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await this.$http.getTotalElevators({});
      if (res.success) {
        this.count = res.data.count;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.top {
  position: absolute;
  top: 30px;
  right: 562px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 280px;
  height: 80px;
  background: url('~@a/images/dtl_bg.png') no-repeat;
  background-size: 100%;
  background-position: 0 0;
  &-l {
    display: flex;
    align-items: center;
    >img {
      width: 48px;
      height: 52px;
    }
    >span {
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 24px;
      color: #FFFFFF;
      line-height: 28px;
      margin-left: 8px;
    }
  }
  &-r {
    span {
      font-family: PangMenZhengDao, PangMenZhengDao;
      font-weight: 400;
      font-size: 40px;
      color: #85DFFF;
      line-height: 47px;
      letter-spacing: 1px;
    }
    em {
      font-style: normal;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #B8EEE4;
      line-height: 19px;
    }
  }
}
</style>