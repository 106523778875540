<template>
  <div class="right3">
    <CommonTitle title="未来24小时故障预警"></CommonTitle>
    <div class="right3-content">
      <div class="title">
        <div class="title-l">
          <img :src="icon" alt="">
          <span>高风险预警总计</span>
        </div>
        <div class="title-r">
          <p><CountTo :startVal='0' :endVal="Number(total)" :duration='3000' separator=""></CountTo>次</p>
        </div>
      </div>
      <div class="box">
        <div class="box-item" v-for="(item,index) in list" :key="index">
          <img :src="item.icon" alt="">
          <span>{{ item.label }}</span>
          <p>
            <CountTo :startVal='0' :endVal="Number(item.value)" :duration='3000' separator=""></CountTo>
            <em>{{ item.unit }}</em>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonTitle from "@c/commonTitle";
export default {
  components: {
    CommonTitle,
  },
  data () {
    return {
      icon: require("@a/images/warning.png"),
      total: 0,
      list: [
        {
          icon: require('@a/images/men.png'),
          label: "门",
          key: "door",
          value: 4,
          unit: "梯次"
        },
        {
          icon: require('@a/images/dianqi.png'),
          label: "电气",
          key: "electrical",
          value: 2,
          unit: "梯次"
        },
        {
          icon: require('@a/images/yeyin.png'),
          label: "曳引",
          key: "traction",
          value: 2,
          unit: "梯次"
        },
      ]
    }
  },
  created () {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await this.$http.getFuture24HourFaultWarnings({});
      if (res.success) {
        const { data } = res;
        this.list.forEach(v => {
          v.value = data[v.key];
        })
        this.total = data.total;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.right3 {
  position: absolute;
  bottom: 30px;
  right: 30px;
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 300px;
  &-content {
    width: 100%;
    flex: 1;
    padding: 16px 40px;
    .title {
      width: 100%;
      height: 67px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: url('~@a/images/yjzj_bg.png') no-repeat;
      background-size: 100%;
      background-position: 0 0;
      padding-right: 30px;
      &-l {
        display: flex;
        align-items: center;
        img {
          width: 69px;
          height: 63px;
        }
        span {
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #FFFFFF;
          line-height: 19px;
        }
      }
      &-r {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #B8EEE4;
        line-height: 19px;
        span {
          font-family: PangMenZhengDao, PangMenZhengDao;
          font-weight: 400;
          font-size: 28px;
          color: #85DFFF;
          line-height: 33px;
          letter-spacing: 1px;
        }
      }
    }
    .box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
      &-item {
        width: 33%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 24px;
        &:first-of-type {
          padding-left: 12px;
          border-right: 1px solid rgba(255,255,255,0.3);
        }
        &:last-of-type {
          border-left: 1px solid rgba(255,255,255,0.3);
        }
        img {
          width: 19px;
          height: 20px;
        }
        >span {
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #E3E9F2;
          line-height: 19px;
          margin: 12px 0;
        }
        p {
          >span {
            font-family: PangMenZhengDao, PangMenZhengDao;
            font-weight: 400;
            font-size: 20px;
            color: #85DFFF;
            line-height: 23px;
          }
          em {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #E3E9F2;
            line-height: 16px;
            font-style: normal;
          }
        }
      }
    }
  }
}
</style>