<!--
 * @Description: 
 * @Author: FeiShu
 * @Date: 2024-09-04 17:13:51
 * @LastEditors: FeiShu
 * @LastEditTime: 2024-09-11 11:14:04
-->
<template>
  <div class="bottom">
    <div class="bottom-title">
      预警记录
    </div>
    <div class="bottom-content">
      <VueSeamlessScroll
          ref="vueSeamlessScroll"
          @mousewheel.native="handleScroll"
          :data="table"
          :class-option="classOption"
          style="height: 100%; overflow: hidden; width: 100%"
        >
        <el-row class="row" v-for="(item,index) in table" :key="index">
          <el-col :span="7">
            <div class="cell">
              <span>电梯编号</span>
              <p>{{ item.device_code }}</p>
            </div>
          </el-col>
          <el-col :span="10" class="col">
            <div class="cell">
              <span>上报内容</span>
              <p>{{ item.remark }}</p>
            </div>
          </el-col>
          <el-col :span="7">
            <div class="cell">
              <span>时间</span>
              <p>{{ item.alarm_time }}</p>
            </div>
          </el-col>
        </el-row>
      </VueSeamlessScroll>
    </div>
  </div>
</template>

<script>
import VueSeamlessScroll from "vue-seamless-scroll";
export default {
  components: {
    VueSeamlessScroll
  },
  watch: {
    table: {
      handler() {
        // 数据更改时也要加
        this.$nextTick(() => {
          this.$refs.vueSeamlessScroll.reset();
        });
      },
      immediate: true,
    },
  },
  data () {
    return {
      classOption: {
        singleHeight: 40,
        limitMoveNum: 5,
      },
      table: []
    }
  },
  created () {
    this.getList();
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.vueSeamlessScroll.reset();
    });
  },
  methods: {
    async getList() {
      const res = await this.$http.getAllWarningRecords({});
      if (res.success) {
        this.table = res.data;
      }
    },
    handleScroll(e) {
      // 改变组件内部 yPos 的值，这样html的translate(0, yPos)就会随之改变
      // e.deltaY是滚动的距离
      this.$refs.vueSeamlessScroll.yPos =
        this.$refs.vueSeamlessScroll.yPos - e.deltaY;
      // 如果是正数 说明是往上滚
      if (this.$refs.vueSeamlessScroll.yPos > 0) {
        this.$refs.vueSeamlessScroll.yPos = 0;
        return;
      }
      // 如果yPos超过内部实际高度的一半则重新到顶部滚动
      // 一半的原因是因为组件实际上创建了两个dom，以达到无缝衔接的效果
      if (
        Math.abs(this.$refs.vueSeamlessScroll.yPos) >
        this.$refs.vueSeamlessScroll.realBoxHeight / 2
      ) {
        this.$refs.vueSeamlessScroll.yPos = 0;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.bottom {
  position: absolute;
  // left: 562px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
  width: 796px;
  height: 340px;
  background: url('~@a/images/yj_bg.png') no-repeat;
  background-size: 100%;
  background-position: 0 0;
  &-title {
    display: flex;
    align-items: center;
    padding-left: 25px;
    width: 230px;
    height: 45px;
    background: url('~@a/images/red_title.png') no-repeat;
    background-size: 100%;
    background-position: 0 0;
    font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;
    font-weight: 400;
    font-size: 24px;
    color: #FF4769;
    line-height: 28px;
    letter-spacing: 2px;
  }
  &-content {
    width: 100%;
    height: 295px;
    padding: 16px;
    .row {
      padding: 4px 0;
      background:  url('~@a/images/li_bg.png') no-repeat;
      background-size: 100%;
      background-color: #121F26;
      background-position: 0 0;
      margin-bottom: 12px;
    }
    .col {
      border-left: 1px solid rgba(255,255,255,0.1);
      border-right: 1px solid rgba(255,255,255,0.1);
    }
    .cell {
      padding: 0 16px;
      span {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: rgba(214,243,255,0.6);
        line-height: 14px;
      }
      p {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: rgba(214,243,255,0.9);
        line-height: 28px;
        white-space: nowrap;
      }
    }
  }
}
</style>