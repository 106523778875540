<!--
 * @Description: 
 * @Author: FeiShu
 * @Date: 2024-09-05 11:32:10
 * @LastEditors: FeiShu
 * @LastEditTime: 2024-09-06 15:52:38
-->
<template>
  <div class="right2">
    <CommonTitle title="维保重点"></CommonTitle>
    <div class="right2-content">
      <div class="box">
        电梯编号{{data.elevator_number}}{{data.maintenance_item}}，预计剩余使用寿命:
        <CountTo :startVal='0' :endVal="Number(data.remaining_life)" :duration='3000' separator=""></CountTo>天。
      </div>
    </div>
  </div>
</template>

<script>
import CommonTitle from "@c/commonTitle";
export default {
  components: {
    CommonTitle,
  },
  data () {
    return {
      data: {
        remaining_life: 108,
        elevator_number: "",
        maintenance_item: ""
      }
    }
  },
  created () {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await this.$http.getKeyMaintenancePlans({});
      if (res.success) {
        const { data } = res;
        this.data = data;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.right2 {
  position: absolute;
  top: 400px;
  right: 30px;
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 300px;
  &-content {
    width: 100%;
    flex: 1;
    padding: 24px 30px;
    .box {
      width: 100%;
      height: 165px;
      background: url('~@a/images/text_bg.png') no-repeat;
      background-size: 100%;
      background-position: 0 0;
      padding: 20px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #E3E9F2;
      line-height: 32px;
      span {
        color: rgba(133, 223, 255, 1);
        font-size: 28px;
        font-family: PangMenZhengDao, PangMenZhengDao;
        font-weight: 400;
      }
    }
  }
}
</style>